import React, { useState, useEffect } from "react";
import { Typography, Box, Fade } from "@mui/material";
import Cards from "./Cards";
import "./HomePage.css";

const HomePage: React.FC = () => {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const opacity = Math.max(1 - scrollY / 300, 0);
  const transformLeft = `translateX(-${scrollY}px)`;
  const transformRight = `translateX(${scrollY}px)`;
  const letterSpacing = `${0.5 + scrollY / 100}em`;

  return (
    <Box
      sx={{
        height: "200vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(180deg, #000000, #131313, #1b1b1b)",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: opacity === 0 ? "none" : "flex",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "white",
            fontFamily: "Rajdhani, sans-serif",
            fontWeight: "300",
            letterSpacing: letterSpacing,
            transform: transformLeft,
            opacity: opacity,
            transition: "transform 0.1s, opacity 0.1s, letter-spacing 0.1s",
          }}
        >
          pvt
        </Typography>
        <Typography
          variant="h1"
          sx={{
            color: "white",
            fontFamily: "Rajdhani, sans-serif",
            fontWeight: "300",
            letterSpacing: letterSpacing,
            transform: transformRight,
            opacity: opacity,
            transition: "transform 0.1s, opacity 0.1s, letter-spacing 0.1s",
          }}
        >
          sec
        </Typography>
      </Box>
      <Cards in={scrollY > 300} />
      <Fade in={scrollY > 300} timeout={500}>
        <Typography
          variant="h4"
          sx={{
            position: "fixed",
            top: 16,
            left: 30,
            color: "white",
            fontFamily: "Rajdhani, sans-serif",
            fontWeight: "300",
          }}
        >
          pvtsec
        </Typography>
      </Fade>
    </Box>
  );
};

export default HomePage;
