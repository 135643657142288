// SingleCard.tsx
import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

interface SingleCardProps {
  cardNumber: number;
}

const SingleCard: React.FC<SingleCardProps> = ({ cardNumber }) => {
  return (
    <Card
      sx={{
        minWidth: 400,
        height: 600,
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        border: "1px solid rgba(255, 255, 255, 0.3)",
        borderRadius: 5,
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
        position: "relative",
        "&:before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "inherit",
          filter: "blur(10px)",
          zIndex: -1,
        },
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" sx={{ color: "white" }}>
          Card {cardNumber}
        </Typography>
        <Typography variant="h5" component="div" sx={{ color: "white" }}>
          This is the content of card {cardNumber}.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SingleCard;
