// Cards.tsx
import React from "react";
import { Box, Fade } from "@mui/material";
import SingleCard from "./SingleCard";

interface CardsProps {
  in: boolean;
}

const Cards: React.FC<CardsProps> = ({ in: inProp }) => {
  return (
    <Fade in={inProp} timeout={500}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 15,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: inProp ? "translate(-50%, -50%)" : "translate(-50%, -50%)",
          transition: "transform 1s ease-out",
        }}
      >
        {[1, 2, 3].map((card) => (
          <SingleCard key={card} cardNumber={card} />
        ))}
      </Box>
    </Fade>
  );
};

export default Cards;
